export const ServiceUrl = {
  COURSES: {
    CATEGORY_LIST: "v1/admin/course/category/list",

    LEVEL_LIST: "v1/admin/course/level/list",

    DEGREE_LIST: "v1/admin/course/degree/list",

    DEPARTMENT_LIST: "v1/admin/course/dept/list",

    COURSE_LIST: "v1/admin/course/list",
    COURSE_LIST_MININFO: "v1/admin/course/min-info",
  },
  COLLEGES: {
    COUNTRY_LIST: "v1/set/list-countries",

    STATE_LIST: "v1/set/list-states",

    DISTRICT_LIST: "v1/set/list-district",

    CITY_LIST: "v1/admin/city/list",
  },

  ADMISSION: {
    SETTINGS_MENU: "v1/admin/settings/menu-list",
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
  },

  SETTINGS: {
    ACADEMIC_YEAR_LIST: "v1/admin/settings/acyear/list",

    COMMUNITY_LIST: "v1/admin/settings/community/list",

    BLOOD_GROUP_LIST: "v1/admin/settings/bgroup/list",

    RELIGION_LIST: "v1/admin/settings/religion/list",

    MOTHER_TONGUE_LIST: "v1/admin/settings/mothertongue/list",

    BATCH_LIST: "v1/admin/settings/batc",
    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",
  },

  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
  AUTHENDICATE: {
    LOGIN: "portal/login/check",
    PHD_LOGIN: "portal/login/check-phd",
  },
  STUDENTS: {
    CHECK_ADD_MOBILE_EMAIL: "portal/v1/stu/check",
    MY_EXAM_SUBJECTS: "portal/v1/stu/exam-subjects",
    GET_OTP: "portal/v1/stu/get-otp",
    VERIFY_OTP: "portal/v1/stu/verify-otp",

    MY_PROFILE: "portal/v1/stu/me",
    UPDATE_BASIC: "portal/v1/stu/update-basic",

    PAYMENT_LINK: "portal/v1/stu/payment-link",
    BACKLOG_PAYMENT_LINK: "portal/v1/stu/backlog-payment-link",
    ATTENDANCE_LACK_PAYMENT_LINK: "portal/v1/stu/att-lack-payment-link",
    GET_STUDENT_ORDERS: "portal/v1/stu/student-orders",

    ORDER_DATA: "portal/student/get_order",
    CHECK_ATTENDANCE_DATA: "portal/v1/stu/check-att",
    LIST_EXAM_MONTH: "portal/v1/stu/list-exam-month",
    GET_RESULT: "portal/v1/stu/get-result",
    GET_REVALUATION_LIST: "portal/v1/stu/get-revaluation-list",
    CHECK_REVALUATION_DATE: "portal/v1/stu/check-revaluation-date",
    UPDATE_REVALUATION_SUBJECTS: "portal/v1/stu/update-revaluation-subjects",
    REVALUATION_PAYMENT_LINK: "portal/v1/stu/revaluation-payment-link",
    SAVE_TAMIL_NAME: "portal/v1/stu/save-tamil-name",

    GET_REQUEST_TYPES: "portal/v1/stu/get-request-types",
    CHECK_GENUINE_CERT_ELIGIBILITY:
      "portal/v1/stu/check-genuine-cert-eligibility",
    REQUEST_PAYMENT_LINK: "portal/v1/stu/request-payment-link",
  },

  PHD_STUDENTS: {
    MY_PHD_PROFILE: "v1/portal/phd/phd-me",
    MY_YEARLY_FEE: "v1/portal/phd/phd-yearly-fee",
    YEARLY_PAYMENT_LINK: "v1/portal/phd/yearly-payment-link",
    PHD_ORDER_DATA: "portal/student/get_order_phd",
    GET_REQUEST_TYPES: "v1/portal/phd/get-request-types",

    REQUEST_PAYMENT_LINK: "v1/portal/phd/request-payment-link",
  },
};
